import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import './login.css';

import {API_ROOT} from './old_infrastructure/const';
import {API_VERSION_PARAM, get_json} from './old_infrastructure/functions';

import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';

import UAParser from 'ua-parser-js';

const LOGIN_POPUP_ANCHOR_ID = 'pkuhelper_login_popup_anchor';

class LoginPopupSelf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_status: 'idle',
            recaptcha_verified: false,
            phase: -1,
            // excluded_scopes: [],
        };

        this.ref = {
            username: React.createRef(),
            email_verification: React.createRef(),
            password: React.createRef(),
            password_confirm: React.createRef(),

            checkbox_terms: React.createRef(),
            checkbox_account: React.createRef(),
        };

        this.popup_anchor = document.getElementById(LOGIN_POPUP_ANCHOR_ID);
        if (!this.popup_anchor) {
            this.popup_anchor = document.createElement('div');
            this.popup_anchor.id = LOGIN_POPUP_ANCHOR_ID;
            document.body.appendChild(this.popup_anchor);
        }
    }

    next_step() {
        if (this.state.loading_status === 'loading') return;
        switch (this.state.phase) {
            case -1:
                this.verify_email('v3', () => {
                });
                break;
            case 0:
                this.do_login(this.props.token_callback);
                break;
        }
    }

    async sha256(message) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string
        return hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
    }

    async hashpassword(password) {
        return await this.sha256(password);
    }

    verify_email(version, failed_callback) {

        const uname = this.ref.username.current.value;
        const sitename = process.env.REACT_APP_TITLE;
        // VALIDATE EMAIL IN FRONT-END HERE
        const body = new URLSearchParams();
        Object.entries({
            uname,
            sitename,
        }).forEach((param) => body.append(...param));
        this.setState(
            {
                loading_status: 'loading',
            },
            () => {
                fetch(API_ROOT + 'api/login/check_user?' + API_VERSION_PARAM(), {
                    method: 'POST',
                    body,
                })
                    .then((res) => res.json())
                    .then((json) => {
                        // COMMENT NEXT LINE
                        //json.code = 2;
                        if (json.code < 0) throw new Error(json.msg);
                        this.setState({
                            loading_status: 'done',
                            phase: json.code,
                        });
                        if (json.code === 3) failed_callback();
                    })
                    .catch((e) => {
                        alert('邮箱检验失败\n' + e);
                        this.setState({
                            loading_status: 'done',
                        });
                        console.error(e);
                    });
            },
        );
    }

    async do_login(set_token) {
        const uname = this.ref.username.current.value;
        const valid_code = this.ref.password.current.value;
        // const valid_code = this.ref.email_verification.current.value;
        const sitename = process.env.REACT_APP_TITLE;
        // let password_hashed = await this.hashpassword(password);
        // let password_hashed = password;
        const device_info = UAParser(navigator.userAgent).browser.name;
        const body = new URLSearchParams();
        Object.entries({
            sitename,
            uname,
            valid_code,
            device_info,
        }).forEach((param) => body.append(...param));

        this.setState(
            {
                loading_status: 'loading',
            },
            () => {
                fetch(API_ROOT + 'api/login/login?' + API_VERSION_PARAM(), {
                    method: 'POST',
                    body,
                })
                    .then(get_json)
                    .then((json) => {
                        if (json.code !== 0) {
                            if (json.msg) throw new Error(json.msg);
                            throw new Error(JSON.stringify(json));
                        }
                        alert(json.msg);
                        if(json.token) {
                          set_token(json.token);
                        }
                        this.setState({
                            loading_status: 'done',
                        });
                        this.props.on_close();
                    })
                    .catch((e) => {
                        alert('登录失败\n' + e);
                        this.setState({
                            loading_status: 'done',
                        });
                    });
            },
        );
    }


    render() {
        window.recaptchaOptions = {
            useRecaptchaNet: true,
        };
        return ReactDOM.createPortal(
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_KEY}
                useRecaptchaNet={true}
            >
                <div>
                    <div className="treehollow-login-popup-shadow"/>
                    <div className="treehollow-login-popup margin-popup">
                        {this.state.phase === -1 && (
                            <>
                                <p>
                                    <b>输入账号来登录 {process.env.REACT_APP_TITLE}</b>
                                </p>
                            </>
                        )}
                        <p style={this.state.phase === -1 ? {} : {display: 'none'}}>
                            <label>
                                手机/邮箱&nbsp;
                                <input style={{width: '12rem'}}
                                       ref={this.ref.username}
                                       type="email"
                                       autoFocus={true}
                                       defaultValue=""
                                       onKeyDown={(event) => {
                                           if (event.key === 'Enter') {
                                               this.next_step();
                                           }
                                       }}
                                />
                            </label>
                            <br/>
                            <label style={{fontSize: '12px'}}>登录验证码将发送到您输入账号(手机/邮箱)</label><br/>
                            <label style={{fontSize: '12px'}}>请确保账号填写正确</label>
                        </p>
                        {this.state.phase === 0 && (
                            <>
                                <p>
                                    <b>输入验证码登录</b>
                                </p>
                                <p>
                                    <label>
                                        验证码&nbsp;
                                        <input
                                            ref={this.ref.password}
                                            type="password"
                                            autoFocus={true}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    this.next_step();
                                                }
                                            }}
                                        />
                                    </label>
                                    <br/>
                                    <label style={{fontSize: '12px'}}>输入您收到的6位数字验证码</label><br/>
                                </p>
                                <p>
                                    <label>
                                        <input type="checkbox" ref={this.ref.checkbox_terms} checked={true}/>
                                        我已阅读并同意
                                        <a href={process.env.REACT_APP_PRIVACY_URL} target={"_blank"}>隐私说明</a>和
                                        <a href={process.env.REACT_APP_RULES_URL} target={"_blank"}>社区规范</a>。
                                    </label>
                                </p>
                            </>
                        )}
                        <p>
                            <button
                                onClick={this.next_step.bind(this)}
                                disabled={this.state.loading_status === 'loading'}
                            >
                                登录
                            </button>
                            <button onClick={this.props.on_close}>取消</button>
                        </p>
                    </div>
                </div>
            </GoogleReCaptchaProvider>,
            this.popup_anchor,
        );
    }
}

export class LoginPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popup_show: false,
        };
        this.on_popup_bound = this.on_popup.bind(this);
        this.on_close_bound = this.on_close.bind(this);
    }

    on_popup() {
        this.setState({
            popup_show: true,
        });
    }

    on_close() {
        this.setState({
            popup_show: false,
        });
    }

    render() {
        return (
            <>
                {this.props.children(this.on_popup_bound)}
                {this.state.popup_show && (
                    <LoginPopupSelf
                        token_callback={this.props.token_callback}
                        on_close={this.on_close_bound}
                    />
                )}
            </>
        );
    }
}

export class RecaptchaV2Popup extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.state = {
            popup_show: false,
        };
        this.on_popup_bound = this.on_popup.bind(this);
        this.on_close_bound = this.on_close.bind(this);
    }

    on_popup() {
        this.setState({
            popup_show: true,
        });
    }

    on_close() {
        this.setState({
            popup_show: false,
        });
    }

    componentDidMount() {
        if (this.captchaRef) {
            console.log('started, just a second...');
            this.captchaRef.reset();
            this.captchaRef.execute();
        }
    }

    onChange(recaptchaToken) {
        localStorage['recaptcha'] = recaptchaToken;
        this.setState({
            popup_show: false,
        });
        this.props.callback();
    }

    render() {
        return (
            <>
                {this.props.children(this.on_popup_bound)}
                {this.state.popup_show && (
                    <div>
                        <div className="treehollow-login-popup-shadow"/>
                        <div className="treehollow-login-popup">
                            <div className="g-recaptcha">
                                <ReCAPTCHA
                                    ref={(el) => {
                                        this.captchaRef = el;
                                    }}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_V2_KEY}
                                    // size={"compact"}
                                    onChange={this.onChange}
                                />
                            </div>

                            <p>
                                <button onClick={this.on_close_bound}>取消</button>
                            </p>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
